export enum FeatureFlag {
  ClimateControlV2 = 'ClimateControlV2',
  BuildingEsg = 'BuildingESG',
  GasEnergyWidget = 'GasEnergyWidget',
  SiteOverviewV2 = 'SiteOverviewV2',
  ResidentApp = 'ResidentApp',
  UtilityConsumption = 'UtilityConsumption',
  UnoccupiedSpacesWidget = 'UnoccupiedSpacesWidget',
  SmartMeters = 'SmartMeters',
  SpaceSearch = 'SpaceSearch'
}